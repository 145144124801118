import React from 'react';
import { connect } from 'react-redux';
import { Dialog, Input, Textarea, DatePicker, Checkbox, notification, FormGroupList } from 'tyb';
import { FormGroupField } from 'tyb';
import { reduxForm } from 'redux-form';
import moment from 'moment';
import { validPhoneNumber } from 'ucode-utils';
const { isvUserModify } = iceStarkData.store.get('commonAction')?.isvUser;
import '../index.scss';


// 新增/编辑 isv账号
@connect(
    state => ({
        isvUserModifyLoaded: state.isvUser.isvUserModifyLoaded,
    }),
    {
        setIsvUserModify: isvUserModify.REQUEST,
    }
)
class ModifyISVUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }

    componentDidUpdate(preProps) {
        if (this.props.visible && 'add,edit'.includes(this.props.visible) && this.props.visible !== preProps.visible) {
            const { record = {} } = this.props;
            this.props.initialize({ startTime: new Date().getTime() + 300000, endTime: new Date().getTime() + 86700000, ...record });
        }

        if ((/2\d{2}$/.test(this.props.isvUserModifyLoaded) && this.props.isvUserModifyLoaded !== preProps.isvUserModifyLoaded)) {
            this.props.handleVisible('', {}, true);
        }
    }

    // 提交
    handleSubmit = (data) => {
        data.account = data.account.trim();
        data.name = data.name.trim();
        // return;
        this.props.setIsvUserModify({ ...data, startTime: new Date(data.startTime).toISOString(), endTime: new Date(data.endTime).toISOString() });
    }

    render() {
        const { visible, record = {}, handleVisible, handleSubmit, } = this.props;
        return (
            <Dialog
                className="ModifyISVUserForm"
                title={record.userId ? '编辑账号' : '创建账号'}
                visible={visible == 'add' || visible == 'edit'}
                onCancel={() => { handleVisible(); }}
                onOk={handleSubmit(this.handleSubmit)}
            >
                <div ref={(e) => {
                    this.aa = e;
                }}>
                    <FormGroupList>
                        <FormGroupField
                            name="account"
                            label="ISV账号"
                            component={Input}
                            required
                        />
                        <FormGroupField
                            name="name"
                            label="用户名称"
                            component={Input}
                            required
                        />
                        <FormGroupField
                            name="mobile"
                            label="联系手机"
                            autoComplete="off"
                            component={Input}
                            required
                        />
                        {!record.userId && <React.Fragment>
                            <FormGroupField
                                name="password"
                                label="密码"
                                autoComplete="off"
                                component={Input}
                                required
                                onFocus={(e) => {
                                    e.target.type = 'password';
                                }}
                            />
                            <FormGroupField
                                name="confirmPassword"
                                label="确认密码"
                                autoComplete="off"
                                component={Input}
                                required
                                onFocus={(e) => {
                                    e.target.type = 'password';
                                }}
                            />
                        </React.Fragment>}
                        <FormGroupField
                            name="startTime"
                            label="有效期"
                            component={DatePicker}
                            type="datetime"
                            required
                        />
                        <FormGroupField
                            name="endTime"
                            component={DatePicker}
                            type="datetime"
                        />
                        <FormGroupField
                            name="remark"
                            label="备注"
                            component={Textarea}
                        />
                    </FormGroupList>

                </div>
            </Dialog >
        )
    }
}

const validate = (values, props) => {
    const errors = {};
    const reg = /\s/;
    if (!values.account || !values.account.trim()) {
        errors.account = '请输入ISV账号';
    } else if (values.account.trim().length > 30) {
        errors.account = '长度不超过30个字符';
    } else if (values.account && (/[\u4e00-\u9fa5]/).test(values.account)) {
        errors.account = '不允许输入汉字';
    }

    if (!values.name || !values.name.trim()) {
        errors.name = '请输入用户名称';
    } else if (values.name.trim().length > 30) {
        errors.name = '长度不超过30个字符';
    }

    if (!values.mobile || !values.mobile.trim()) {
        errors.mobile = "请输入联系手机";
    } else if (!validPhoneNumber(values.mobile)) {
        errors.mobile = "请输入正确的手机号";
    }

    if (!values.password || !values.password.trim()) {
        errors.password = '请输入密码';
    }

    else if (!/(?!^\d+$)(?!^[A-Za-z]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S{16,30}$/.test(values.password)) {
        errors.password = '数字、字母、特殊字符，两种组合以上，长度大于15，小于31个字符';
    }

    if (!values.confirmPassword || !values.confirmPassword.trim()) {
        errors.confirmPassword = '请输入确认密码';
    }
    else if (values.confirmPassword != values.password) {
        errors.confirmPassword = '密码不一致';
    }
    else if (!/(?!^\d+$)(?!^[A-Za-z]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S{16,30}$/.test(values.confirmPassword)) {
        errors.confirmPassword = '数字、字母、特殊字符，两种组合以上，长度大于15，小于31个字符';
    }

    let startTime = values.startTime;
    let endTime = values.endTime;
    if (typeof (startTime) === 'string') {
        startTime = new Date(startTime);
    }
    if (typeof (endTime) === 'string') {
        endTime = new Date(endTime);
    }

    if (moment(endTime).isBefore(moment(startTime))) {
        errors.endTime = '结束时间必须大于开始时间';
    }

    if (values.remark && values.remark.trim().length > 1000) {
        errors.remark = '长度不超过1000个字符';
    }

    // 校验空格，account,name,remark不能输入空格
    if (reg.test(values.account)) {
        errors.account = '不能输入空格';
    }
    if (reg.test(values.name)) {
        errors.name = '不能输入空格';
    }
    if (reg.test(values.mobile)) {
        errors.mobile = '不能输入空格';
    }
    if (reg.test(values.password)) {
        errors.password = '不能输入空格';
    }
    if (reg.test(values.confirmPassword)) {
        errors.confirmPassword = '不能输入空格';
    }
    if (reg.test(values.remark)) {
        errors.remark = '不能输入空格';
    }

    return errors;
}

export default reduxForm({
    form: 'ModifyISVUserForm',
    initialValues: {

    },
    validate
})(ModifyISVUser)


