import React from 'react';
import { connect } from 'react-redux';
import { Button, SearchInput, DataTable, Pager } from 'tyb';
import enums from '@/assets/enum.json';
import { formatDate } from 'ucode-utils';
const { isvUser, isvUserStatus } = iceStarkData.store.get('commonAction')?.isvUser;

import ModifyISVUser from './Components/ModifyISVUser';
import ModifyISVUserPwd from './Components/ModifyISVUserPwd';


import './index.scss';

/* isv账号 */
@connect(
    state => ({
        permissionIds: state.users.permissionIds||[],
        loading: state.isvUser.loading,
        isvUser: state.isvUser.isvUser,
        isvUserStatusLoaded: state.isvUser.isvUserStatusLoaded,
    }),
    {
        getIsvUser: isvUser.REQUEST,
        setIsvUserStatus: isvUserStatus.REQUEST,
    })
export default class ISVAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: [],
        };
        this.params = { page: 0, size: 20 };
        this.status = { ENABLE: '禁用', DISABLE: '启用' };
        this.columns = [
            {
                key: 'account',
                dataIndex: 'account',
                title: 'ISV账号',
                render: (val, record) => <a style={{ fontSize: '14px', wordBreak: 'break-all' }} onClick={() => { this.props.history.push(`/dataisvUser/whitelist?userId=${record.userId}&name=${record.account}`) }}>{val}</a>
            },
            {
                key: 'name',
                dataIndex: 'name',
                title: '用户姓名',
            },
            {
                key: 'mobile',
                dataIndex: 'mobile',
                title: '联系手机',
            },
            {
                key: 'remark',
                dataIndex: 'remark',
                title: '备注',
            },
            {
                key: 'createdName',
                dataIndex: 'createdName',
                title: '创建者',
            },
            {
                key: 'createdTime',
                dataIndex: 'createdTime',
                title: '创建时间',
                render: (val) => <div>{formatDate(val)}</div>
            },
            {
                key: 'startTime',
                dataIndex: 'startTime',
                title: '账号有效期',
                render: (val, record) => <React.Fragment><div>{formatDate(record.startTime)}</div><div>{formatDate(record.endTime)}</div></React.Fragment>
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: '状态',
                render: (val) => <div>{val == 'ENABLE' ? '有效' : '无效'}</div>
            },
            {
                key: 'operate',
                dataIndex: 'operate',
                title: '操作',
                render: (val, record) => (
                    <div className="operate">
                        {this.props.permissionIds.includes('ws.isvUser.edit') && <a href="javascript:;" onClick={() => { this.handleVisible('edit', record) }}>编辑</a>}
                        {this.props.permissionIds.includes('ws.isvUser.allocation') && <a href="javascript:;" onClick={() => { this.props.history.push(`/dataisvUser/allocation?userId=${record.userId}&name=${record.account}`) }}>分配</a>}
                        {this.props.permissionIds.includes('ws.isvUser.status') && <a href="javascript:;" onClick={() => { this.handleOperate(record) }}>{this.status[record.status]}</a>}
                        {this.props.permissionIds.includes('ws.isvUser.resetPassword') && <a href="javascript:;" onClick={() => { this.handleVisible('password', record) }}>重置密码</a>}
                    </div>)
            },
        ]
    }

    componentDidMount() {
        this.props.getIsvUser(this.params);
    }

    componentDidUpdate(preProps) {
        if ((/2\d{2}$/.test(this.props.isvUserStatusLoaded) && this.props.isvUserStatusLoaded !== preProps.isvUserStatusLoaded)) {
            this.props.getIsvUser(this.params);
        }
    }

    getColumns = () => {
        return this.columns.map(column => {
            if (column.key === 'status') {
                return {
                    ...column,
                    headerRender: this.renderStatusHeader
                }
            } else {
                return column;
            }
        })
    }

    renderStatusHeader = (col) => {
        const list = [
            { text: '有效', value: 'ENABLE' },
            { text: '无效', value: 'DISABLE' },
        ];

        return (
            <DataTable.FilterableColumnHeader
                list={list}
                value={this.state.status}
                onFilter={this.handleStatusFilter}
            >
                {col.title}
            </DataTable.FilterableColumnHeader>
        )
    }

    handleStatusFilter = (value) => {
        this.setState({ status: value });
        this.params.status = value;
        this.params.page = 0;
        this.props.getIsvUser(this.params);
    }

    search = () => {
        this.setState({
            page: 0,
        });
        this.params.page = 0;
        this.props.getIsvUser(this.params);
    }

    onPageChange = (val) => {
        this.setState({ page: val });
        this.params.page = val;
        this.props.getIsvUser(this.params);
    }

    onPerPageChange = (val) => {
        this.setState({ perPageCount: val });
        this.params.size = val;
        this.params.page = 0;
        this.props.getIsvUser(this.params);
    }

    handleVisible = (type, record = {}, refulsh) => {
        this.setState({ visible: type, record });
        if (refulsh) {
            this.props.getIsvUser(this.params);
        }
    }

    handleOperate = (record) => {
        this.props.setIsvUserStatus({ userId: record.userId, status: record.status == 'ENABLE' ? 'DISABLE' : 'ENABLE' });
    }

    render() {
        const { keywords, page, perPageCount = 20, visible, record } = this.state;
        const { permissionIds, loading, isvUser = {} } = this.props;
        const { content, totalElements } = isvUser;
        return (
            <React.Fragment>
                <div className="">
                    <div className="search-bar">
                        {permissionIds.includes('ws.isvUser.add') && <Button onClick={() => { this.handleVisible('add'); }}>创建账号</Button>}
                        <div className="search-input-block">
                            <SearchInput
                                onChange={(val) => { this.params.keywords = val; this.setState({ keywords: val }) }}
                                onSearch={this.search}
                                value={keywords}
                                placeholder="搜索账号名称"
                            />
                        </div>
                    </div>

                    {/* <div className="container"> */}
                        <DataTable
                            columns={this.getColumns()}
                            data={content}
                            rowKey="id"
                            loading={loading}
                            rowClassName={(record) => record.status == 'DISABLE' ? 'table-gray-row' : ''}
                        />
                            {content && content.length > 0 && <div className="page-padding20"><Pager
                            total={totalElements}
                            current={page}
                            perPageCountList={[10, 20, 50]}
                            perPageCount={perPageCount}
                            onPageChange={this.onPageChange}
                            onPerPageChange={this.onPerPageChange}
                        />
                        </div>}
                    {/* </div> */}
                </div>
                <ModifyISVUser
                    record={record}
                    visible={visible}
                    handleVisible={this.handleVisible}
                />
                <ModifyISVUserPwd
                    record={record}
                    visible={visible}
                    handleVisible={this.handleVisible}
                />
                {/* <AllocationISVUser
                    record={record}
                    visible={visible}
                    handleVisible={this.handleVisible}
                /> */}
            </React.Fragment>
        )
    }
}



