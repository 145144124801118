import React from 'react';
import { connect } from 'react-redux';
import { Dialog, Input, FormGroupList } from 'tyb';
import { FormGroupField } from 'tyb';
import { reduxForm } from 'redux-form';
const { isvUserModifyPwd } = iceStarkData.store.get('commonAction')?.isvUser;
import '../index.scss';


// 新增/编辑 isv账号
@connect(
    state => ({
        isvUserModifyLoaded: state.isvUser.isvUserModifyLoaded,
    }),
    {
        setIsvUserModifyPwd: isvUserModifyPwd.REQUEST,
    }
)
class ModifyISVUserPwd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }

    componentDidUpdate(preProps) {
        if (this.props.visible && this.props.visible != preProps.visible) {
            this.props.initialize();
        }

        if ((/2\d{2}$/.test(this.props.isvUserModifyLoaded) && this.props.isvUserModifyLoaded !== preProps.isvUserModifyLoaded)) {
            this.props.handleVisible('', {}, true);
        }
    }

    // 提交
    handleSubmit = (data) => {
        data.password = data.password.trim();
        data.confirmPassword = data.confirmPassword.trim();
        this.props.setIsvUserModifyPwd({ userId: this.props.record.userId, password: data.password });
    }

    render() {
        const { visible, handleVisible, handleSubmit, } = this.props;
        return (
            <Dialog
                className="ModifyISVUserPwdForm"
                title="重置密码"
                visible={visible == 'password'}
                onCancel={() => { handleVisible(); }}
                onOk={handleSubmit(this.handleSubmit)}
            >
                <div>
                    <FormGroupList>
                        <FormGroupField
                            name="password"
                            label="密码"
                            type="password"
                            autoComplete="off"
                            component={Input}
                            required
                        />
                        <FormGroupField
                            name="confirmPassword"
                            label="确认密码"
                            type="password"
                            autoComplete="off"
                            component={Input}
                            required
                        />
                    </FormGroupList>
                </div>
            </Dialog >
        )
    }
}

const validate = (values, props) => {
    const errors = {};
    const reg = /\s/;
    
    if (!values.password || !values.password.trim()) {
        errors.password = '请输入密码';
    }
    // else if (values.password.trim().length > 30 || values.password.trim().length < 16) {
    //     errors.password = '长度不超过30个字符,不得小于16个字符';
    // } 
    else if (!/(?!^\d+$)(?!^[A-Za-z]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S{16,30}$/.test(values.password)) {
        errors.password = '数字、字母、特殊字符，两种组合以上，长度大于15，小于31个字符';
    }

    if (!values.confirmPassword || !values.confirmPassword.trim()) {
        errors.confirmPassword = '请输入确认密码';
    }
    //  else if (values.confirmPassword.trim().length > 30 || values.confirmPassword.trim().length < 16) {
    //     errors.confirmPassword = '长度不超过30个字符,不得小于16个字符';
    // } 
    else if (values.confirmPassword != values.password) {
        errors.confirmPassword = '密码不一致';
    } else if (!/(?!^\d+$)(?!^[A-Za-z]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S{16,30}$/.test(values.confirmPassword)) {
        errors.confirmPassword = '数字、字母、特殊字符，两种组合以上，长度大于15，小于31个字符';
    }

    if(reg.test(values.password)){
        errors.password = '不能输入空格';
    }
    if(reg.test(values.confirmPassword)){
        errors.confirmPassword = '不能输入空格';
    }
    return errors;
}

export default reduxForm({
    form: 'ModifyISVUserPwdForm',
    initialValues: {

    },
    validate
})(ModifyISVUserPwd)


